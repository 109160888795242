@import "../../theme.css";
nav {
  width: 100%;
  background: var(--primary-color);
  height: 64px;
  color: white;
  display: grid;
  grid-template-columns: 3fr 4fr 3fr;
  align-items: center;
  gap: 0rem 0.5rem;
  padding: 0px 32px 0 32px;
  box-shadow: 0 0.125rem 0.425rem rgba(90, 97, 105, 0.36);
  color: #000000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-image: linear-gradient(
    to right,
    var(--primary-color-dark) 50%,
    var(--primary-color-light) 100%
  );
}
.navIconsContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.navIconsContainer a {
  display: flex;
  margin-right: 0.5rem;
}
.navIconsContainer img {
  height: 42px;
  padding: 8px;
  object-fit: contain;
  cursor: pointer;
  border-radius: 8px;
  /* box-shadow: 0 0 8px rgba(63, 63, 68, 0.3); */
}
.hamburger {
  padding: 6px;
  width: 40px;
  height: 40px;
  color: var(--primary-color);
  border-radius: 4px;
  display: none;
}
.hamburger:hover {
  cursor: pointer;
}
.hamburger:active {
  color: var(--primary-color-dark);
}

.navStart {
  display: flex;
  align-items: center;
  font-size: 18px;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  flex-grow: 1;
  justify-content: flex-start;
}
.navStart img {
  height: 55px;
  width: auto;
  flex: 1;
}

/* Bottom Bar */

.bottomBar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  z-index: 2;
  align-items: center;
  justify-content: center;
  display: none;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  height: 64px;
}

.bottomBar .item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0.375rem 0.1rem;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

.bottomBar .item:active {
  background-color: transparent !important;
  user-select: none !important;
  background: transparent !important;
}

.bottomBar span {
  font-size: 12px;
  font-weight: 700;
  color: black;
}

.bottomBar img {
  width: 24px;
  height: 24px;
  margin-bottom: 0.075rem;
}

.bottomBarHomeContainer {
  position: relative;
}

.bottomBarHome {
  background-image: linear-gradient(
    to right,
    var(--primary-color-dark) 50%,
    var(--primary-color-light) 100%
  );
  background-size: 150% 150%;
  width: 60px;
  height: 60px;
  border-radius: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -36px;
  user-select: none;
  -webkit-animation: colorAnimation 3s ease infinite;
  -moz-animation: colorAnimation 3s ease infinite;
  animation: colorAnimation 3s ease infinite;
  box-shadow: 0 0 8px 2px rgba(63, 63, 68, 0.3);
}

.appPopUpContainer {
  height: 84px;
  background-color: #1d262d;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 64px;
  padding: 1rem;
  justify-content: space-between;
  align-items: center;
  display: none;
  z-index: 1;
}

.showAppPopUpContainer {
  display: flex;
}

.appPopUpContainer p {
  color: white;
  font-size: 18px;
  font-weight: 600;
}
.appPopUpContainer span {
  color: rgb(202, 202, 202);
  font-size: 12px;
  font-weight: 400;
}

.cancelAppPopup {
  position: absolute;
  right: 4px;
  top: 4px;
  width: 16px !important;
}

@keyframes colorAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 50% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@media (min-width: 767px) {
  .appPopUpContainer {
    display: none;
  }
}
@media (max-width: 800px) {
  .bottomBar {
    display: grid;
  }
  nav {
    grid-template-columns: 2fr 3fr;
    padding: 0px 16px 0 16px;
    height: 128px;
  }
  .searchContainer {
    width: 100%;
  }
  .navSearch {
    order: 3;
    grid-column: span 2;
    margin-bottom: 0.75rem;
  }

  .navStart img {
    width: 100%;
    margin: 0;
    object-fit: contain;
  }
  .btSearch {
    width: 40px;
  }
}
@media (max-width: 572px) {
  nav {
    gap: 0;
  }
  .navIconsContainer a {
    margin-right: 0;
  }
}
