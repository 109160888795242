/*loginPopup*/
.loginPopup {
  /* display: block; */
  background: var(--primary-color);
  padding: 50px;
  border-radius: 8px;
  text-align: center;
}
.loginPopup button {
  background: black;
  color: #fff;
}
.loginPopup h2 {
  margin-bottom: 10px;
}
.loginPopup p {
  margin-bottom: 20px;
}

.loginPopupNumber {
  display: block;
  margin-bottom: 15px;
}
.loginPopupNumber input {
  border-radius: 8px;
  width: 80%;
  text-align: center;
}
.loginPopupButton {
  display: block;
}
